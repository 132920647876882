<template>
  <div class="product-list-card">
    <div class="header">
      <div class="header-title">
        {{ type === 'ranking' ? 'Ranking de produtos' : 'Produtos em'}} <span>{{ type === 'ranking' ? 'mais utilizados' : 'estoque' }}</span>
      </div>
      <div>
        <multiselect
          v-model="orderAux"
          track-by="value"
          label="label"
          :options="type === 'ranking' ? rankingOptions : productOptions"
          :allow-empty="false"
          :option-height="20"
          :showLabels="false"
          :showNoResults="false"
          placeholder="Selecionar"
          @select="handleOrdination"
        >
          <template slot="caret">
            <div class="chevron">
              <v-chevron-down />
            </div>
          </template>
          <template slot="singleLabel" slot-scope="{ option }">
              {{ option.label }}
          </template>

          <template slot="noOptions">
            Nenhuma opção
          </template>

          <template slot="noResult">
            Nenhum resultado
          </template>
        </multiselect>
      </div>
    </div>
    <div class="content">
      <div class="product-line" v-for="(product, index) in products" :key="product.id">
        <div>
          <span v-if="type === 'ranking'" class="ranking-value">{{normalizeProductNumber(getRakingPosition(index), 2)}}</span>
          <span v-if="type === 'products'" class="ranking-value">{{normalizeProductNumber(getRakingPosition(index), 2)}}</span>
          {{ product.name }}
        </div>
        <div
          :class="{
            'balance': true,
            'balance-warning': product.product_balance.balance === product.minimum_quantity,
            'balance-error': product.product_balance.balance < product.minimum_quantity
          }"
        >
          {{ normalizeProductNumber(product.product_balance.balance, 2) }}
        </div>
      </div>
      <div v-if="!loading && !products.length" class="product-line">
        Nenhum produto cadastrado
      </div>
      <div class="product-list-pagination-position">
        <b-pagination
          v-model="page"
          :total-rows="count"
          :per-page="limit"
          first-number
          last-number
          size="sm"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ChevronDown from '@/assets/icons/chevron-down.svg'
import { normalizeProductNumber } from '@/utils/product-helper';
export default {
  components: {
    'v-chevron-down': ChevronDown,
  },
  props: {
    type: String,
  },
  created() {
    this.getProducts();
  },
  data() {

    const productOptions = [
      { label: 'Menor quantidade', value: { key: 'balance', order: 'ASC'} },
      { label: 'Maior quantidade', value: { key: 'balance', order: 'DESC'} },
      { label: 'Mais recentes', value: { key: 'created_at', order: 'ASC'} },
      { label: 'Ordem alfabética', value: { key: 'name', order: 'ASC'} }
    ];

    const rankingOptions = [
      {label: 'Ordem crescente', value: { key: 'balance', order: 'ASC'}},
      {label: 'Ordem decrescente', value: { key: 'balance', order: 'DESC'} }
    ];

    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      page: 1,
      limit: 0,
      count: 0,
      key: 'balance',
      order: this.type === 'ranking' ? 'DESC' : 'ASC',
      products: [],
      loading: false,
      orderAux: this.type === 'ranking' ? rankingOptions[1] : productOptions[0],
      productOptions,
      rankingOptions,
    }
  },
  methods: {
    normalizeProductNumber,
    handleOrdination(el) {
      this.order = el.value.order;
      this.key = el.value.key;
      this.getProducts();
    },
    getProducts() {
      this.loading = true;
      this.api.getWarehouseDashboardProducts(this.clinic.id, this.key, this.order, this.page)
      .then(res => {
        this.products = res.data.data;
        this.limit = res.data.per_page;
        this.count = res.data.total;
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => (this.loading = false));
    },
    getRakingPosition(index) {
      const positions = Array.from(Array(this.count).keys());
      if (this.order === 'ASC') {
        positions.reverse();
      }
      return positions[index * this.page] + 1;
    },
  },
  watch: {
    page: function () { this.getProducts() },
  }
}
</script>
<style lang="scss" scoped>
  .product-list-card {
    width: 100%;
    height: 100%;
    border: 1px solid var(--neutral-200);
    border-radius: 8px;
    text-align: left;
  }
  .header {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 4px 24px 23.5px;
    border-bottom: 1px solid var(--neutral-200);
  }
  .header-title {
    font-weight: 700;
    font-size: 16px;

    span {
      color: var(--blue-500);
    }
  }
  .content {
    padding: 10px 24px;
  }
  .ranking-value {
    font-family: 'Red Hat Display';
    font-weight: 700;
    color: var(--neutral-500);
    margin-right: 10px;
  }
  .product-line {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    padding: 10px 0;
  }
  .balance {
    font-weight: 700;
    color: var(--blue-500);
  }
  .balance-warning {
    color: var(--orange) !important;
  }
  .balance-error {
    color: var(--states-error) !important;
  }
  .product-list-pagination-position {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
</style>
